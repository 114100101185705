import React from "react"
import kebabCase from "lodash/kebabCase"
import { MainLayout } from "@components/layouts"
import { Seo } from "@components"
import { PageProps, Link, graphql } from "gatsby"
import { Query } from "types/gatsby-graphql"

interface IProps extends PageProps {
  data: Query
}

const TagsPage: React.VFC<IProps> = ({
  data: {
    allMarkdownRemark: { group },
    site,
  },
}) => (
  <MainLayout>
    <Seo title="All tags" />
    <div className="content">
      <h1>All tags</h1>
      <ul>
        {group.map(tag => (
          <li key={tag.fieldValue}>
            <Link to={`/tags/${kebabCase(tag.fieldValue!!)}/`}>
              {tag.fieldValue} ({tag.totalCount})
            </Link>
          </li>
        ))}
      </ul>
    </div>
  </MainLayout>
)

export default TagsPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`
